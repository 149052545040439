import React, { useState, useRef, useEffect } from "react";
import { FacebookShareCount, FacebookShareButton, HatenaShareCount, OKShareCount, PinterestShareCount, RedditShareCount, TumblrShareCount, VKShareCount, RedditShareButton } from "react-share";

import { useNavigate } from "react-router-dom";

// Redux toolkit
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../Store/PostsSlice";
import axios from "axios";
import StorageService from "../../services/StorageService";
import { get, post } from "../../api/api";
// emoji picker
import EmojiPicker from "emoji-picker-react";

// Models
import { BeforeLoginMessage } from "../Models/Index";

// Component
import CommentAdd from "./CommentAdd";

// Style
import "../../style/MochatPostSection.css";
import { toast } from "react-toastify";

// Icons
import { BsThreeDotsVertical } from "react-icons/bs";
import { DownArrow1Icon, TopArrowIcon, Notification2Icon, ChatIcon, ShareIcon, DeletePostIcon, DarkEmojiIcon, EditIcon, EditIconSvg } from "../../constant/IconsPath";
import { logo4 } from "../../constant/imagePath";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import EditPostSection from "./EditPostSection";
import { updateTrendingPostData, updateTrendingPostDataOnSearch } from "../../Store/TrendingPageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faCopy, faStar } from "@fortawesome/free-regular-svg-icons";
import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import star from "../../../src/assets/img/shooting.png";
import star2 from "../../../src/assets/img/shooting2.png";
import { SyncLoader } from "react-spinners";
import Read_More from "../readmore/Read_More";

const MochatPostSection = ({ data: data1, addMargin, sepratePage, updateState, url, disableThreeDot }) => {
  const [postData, setPostData] = useState({});
  let userId = StorageService?.getValue("userId");
  const [homeView, setHomeView] = useState(false);
  const { postData: TopPostData } = useSelector((state) => state.Trending);
  let data;
  if (data1?.id === postData?.id) {
    data = postData;
  } else {
    data = data1;
  }
  const { id, userName: name, content, title, createdAt: date, convertedDate, profilePicture: logo, picture: postImage, imagePath, commentCount, slugUrl } = data;
  const someProfile = StorageService.getValueProfilePic("profilePic");
  const secondSrc = someProfile != "null" ? `data:image/jpeg;base64,${someProfile}` : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shareUrl = "https://filmwaffle.com/movietalk/"; // Your page URL
  const handleFacebookShareClick = (id) => {
    setClickedId(id);
  };
  const copyUrlToClipboard = (slug) => {
    const url = `${shareUrl}${slug}`; // Gets the current page URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Post link is copied!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const [flag, setFlag] = useState(false);
  const [pushAndDown, setPushAndDown] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const inputFieldMessage = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [num, setNum] = useState(5);
  const [isEmpty, setIsEmpty] = useState(true);
  const [limitedCommentShow, setLimitedCommentShow] = useState([1, 2, 3, 4, 5, 6]);
  const [comments, setComment] = useState([]);
  const [messageForLogin, setMessageForLogin] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [topic, setTopic] = useState(false);
  const [marked, setMarked] = useState(false);
  const [EditPost, setEditPost] = useState(false);
  const [EditPostData, setEditPostData] = useState({});
  const [Share, setShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  // let Username = StorageService?.getValue("userName")
  const PostOption = useRef("");
  const EmojiIcon = useRef("");
  useEffect(() => {
    if (id && sepratePage) {
      getNewComentes(id);
    }
  }, [id, sepratePage]);
  useEffect(() => {
    let token = StorageService?.getJwt();
    if (token) {
      setHomeView(false);
    } else {
      setHomeView(true);
    }
    const handler = (e) => {
      if (PostOption.current && !PostOption.current.contains(e.target)) {
        setFlag(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const getNewComentes = async (val) => {
    setLoading(true);
    try {
      let res = await post(`/Comment/ReadPostComments/${val}?userId=${userId}`);
      let data = await res.json();
      if (res && data) {
        setComment(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching top trending posts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler1 = (e) => {
      if (EmojiIcon.current && !EmojiIcon.current.contains(e.target)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.removeEventListener("mousedown", handler1);
    };
  }, []);

  const showCommentHandel = (id) => {
    getNewComentes(id);
    setShowCommentSection(!showCommentSection);
  };

  const getDataById = async (postId) => {
    const token = StorageService.getJwt();
    try {
      setLoading(true);
      const res = await axios.post(
        `${StorageService?.baseURL}/Post/ReadPost/${postId}?userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPostData(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching post data:", error);
      setLoading(false);
    }
  };

  const CommentAddFunction = async (id) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    const token = StorageService.getJwt();

    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let body = {
        content: inputValue,
      };
      let res = await axios.post(`${StorageService?.baseURL}/Comment/CreateComment/${id}`, body, header);
      if (res.status === 200) {
        setInputValue("");
        getNewComentes(id);
        // toast.success("Comment added successfully");
        // updateState();
        // dispatch(stopLoading());
        setFlag(false);
      } else {
        toast.error(res);
        dispatch(stopLoading());
        setFlag(false);
      }
    } catch (error) {
      dispatch(stopLoading());
      setFlag(false);
      console.error("Error deleting post:", error);
    }
  };

  const setInputHeight = (element, defaultHeight) => {
    if (element) {
      if (element.target.value.trim().length !== 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }

      setInputValue(element.target.value);

      const target = element.target ? element.target : element;
      target.style.height = defaultHeight;
      target.style.height = `${target.scrollHeight}px`;
    }
  };

  const setInputHeight1 = (element, defaultHeight) => {
    if (element) {
      setIsEmpty(false);

      setInputValue((prevValue) => `${prevValue} ${element.emoji}`);

      const typingArea = document.querySelector(".typingArea");
      typingArea.style.height = defaultHeight;
      typingArea.style.height = `${typingArea.scrollHeight}px`;
    }
  };

  const pageNavigateFunction = () => {
    navigate(`/movietalk/${slugUrl}`, {
      state: { id: clickedId || id, slugUrl }, // Use clickedId if available, otherwise use id
    });
  };

  const DeletePost = async (idd) => {
    const token = StorageService.getJwt();
    dispatch(startLoading());
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let res = await axios.post(`${StorageService?.baseURL}/Post/DeletePost/${idd}`, {}, header);
      if (res.status === 200) {
        toast.success(res.data);
        // updateState();
        let filterData = TopPostData?.filter((ele) => ele?.id !== idd);
        dispatch(updateTrendingPostDataOnSearch(filterData));
        dispatch(stopLoading());
        setFlag(false);
      } else {
        toast.error(res);
        dispatch(updateTrendingPostDataOnSearch(TopPostData));
        dispatch(stopLoading());
        setFlag(false);
      }
    } catch (error) {
      dispatch(stopLoading());
      setFlag(false);
      console.error("Error deleting post:", error);
    }
  };

  const UpVote = async (PostId) => {
    // homeView ? setMessageForLogin(!messageForLogin) : setPushAndDown(true);
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/UpVote/${PostId}?userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`, header);

      getDataById(PostId);
      // updateState();
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const DownVote = async (PostId) => {
    // homeView ? setMessageForLogin(!messageForLogin) : setPushAndDown(false);
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/DownVote/${PostId}?userId=${StorageService?.getValue("userId") ? StorageService?.getValue("userId") : null}`, header);

      // updateState();
      getDataById(PostId);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const addBookMark = async (PostId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    const token = StorageService.getJwt();
    dispatch(startLoading());
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Post/AddBookmark?postId=${PostId}`, header);
      let data = await res.json();
      if (data?.post?.bookmark === true) {
        toast.success("Bookmark added successfully!");
      } else {
        toast.success("Bookmark updated successfully!");
      }
      getDataById(PostId);
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      console.error("Error adding bookmark:", error);
    }
  };
  const convertToLinks = (inputText) => {
    const specificDomains = [
      "filmwaffle.com",
      "disneyplus.com",
      "netflix.com",
      "appletv.com",
      "paramountplus.com",
      "shudder.com",
      "youtube.com",
      "primevideo.com",
      "nowtv.com",
      "imdb.com",
      "youtu.be",
      "wikipedia.org",
    ];

    const specificDomainPattern = new RegExp(`\\b(https?:\\/\\/)?(www\\.)?(${specificDomains.join("|")})(\\/[^\\s]*)?\\b`, "gi");

    const linkifiedText = inputText.replace(specificDomainPattern, (match) => {
      const url = match.startsWith("http") ? match : `https://${match}`;
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="linkified">${match}</a>`;
    });

    return { __html: linkifiedText };
  };

  const updateImageUrl = (url) => {
    const newBaseUrl = "https://betaapi.filmwaffle.com/images/";
    if (url.includes("https://betaapi.mochatforum.com")) {
      let imageName = url.split(/Images[\/\\]/).pop();
      return newBaseUrl + imageName;
    } else {
      let cleanedUrl = url.replace(/^(Images[\/\\])/, "");
      return newBaseUrl + cleanedUrl;
    }
  };

  return (
    <React.StrictMode>
      {<BeforeLoginMessage helpModal={messageForLogin} setHelpModal={setMessageForLogin} />}
      <div
        className={`shadow-[0_4px_32px_rgba(0,0,0,0.08)] mb-5 p-[30px] rounded-lg responsive_post_design ${homeView && "homeView"}`}
        style={{
          margin: addMargin ? "2.5rem 0" : "",
          backgroundColor: "#131313",
        }}
      >
        <div className="flex items-center justify-between top_responsive_post_design">
          <div className="flex items-center justify-center">
            <div>
              <img
                src={data?.readUserModel?.profilePicture ? `data:image/png;base64,${data?.readUserModel?.profilePicture}` : logo4}
                alt="Description of the image"
                style={{
                  height: "40px",
                  width: "40px",
                  objectFit: "cover",
                  borderRadius: "100px",
                }}
              />
            </div>

            <div className="ml-4">
              <h3 className="sm:text-xl font-bold mb-1 text-white">{name}</h3>
              <p className="text-[#999999] font-normal text-sm -mb-1">{data?.readUserModel?.userName ? data?.readUserModel?.userName : title}</p>
              <small className="font-normal text-xs" style={{ color: "rgba(255, 255, 255, 0.25)" }}>
                {/* {formattedDate ? formattedDate : "null"} */}
                {convertedDate ? convertedDate : "null"}
              </small>
            </div>
          </div>

          {/* ************************** EDIIIITTTTTTTTTTTT POSTTTTT*********************************************************** */}
          {EditPost && (
            <EditPostSection
              EditPost={EditPost}
              setEditPost={setEditPost}
              data={EditPostData}
              flag={flag}
              setFlag={setFlag}
              MainClassName={"image_upload"}
              open={topic}
              setTopic={setTopic}
              setPostData={setPostData}
            />
          )}
          {/* <EditPostSection/> */}

          <div className="flex items-center justify-center -mr-[12px] sm:-mr-0">
            {data?.editFlag && !disableThreeDot && (
              <div
                className="sm:ml-3 sm:w-[36px] sm:h-[36px] sm:border-solid sm:border-[0.9px] sm:border-[#000] sm:rounded-full flex items-center justify-center sm:bg-[#000] optionBtn"
                style={{ position: "relative", cursor: "pointer" }}
                ref={PostOption}
              >
                <BsThreeDotsVertical className="text-[#fff] p-1 text-3xl" onClick={() => setFlag(!flag)} />
                {flag && (
                  <div className="option">
                    {data.editFlag === true && (
                      <>
                        <div className="option1">
                          <DeletePostIcon />
                          <p onClick={() => DeletePost(id)}>Delete this post</p>
                        </div>
                        <div
                          className="option1"
                          onClick={() => {
                            setEditPostData(data);
                            setEditPost(!EditPost);
                          }}
                        >
                          <EditIconSvg />
                          <p>Edit Post</p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="py-1 description">
        <div className="Img-content" >
          <div className="dis-under" style={{display: window.innerWidth <= 768 ? (sepratePage ? "block" : "flex") : "block",}}>
          <h5 className="title text-white" onClick={() => pageNavigateFunction()} style={{ cursor: sepratePage || homeView ? "auto" : "pointer" }}>
            {title || ""}
          </h5>
          {/* <div className={`${sepratePage ? 'block' : 'hidden'} sm:block`}> */}
          {imagePath && imagePath?.length > 40 && (
            <div className={`image-container`} style={{ height: "auto", justifyContent: 'center', alignItems: 'center',  width: window.innerWidth <= 768 ? (sepratePage ? "100%" : "250px") : "100%", overflow: "hidden" }}>
              <img
                src={updateImageUrl(imagePath)}
                alt="logo"
                className="image"
                style={{
                  height: "auto",
                  display: "block",
                  width: window.innerWidth <= 768 ? (sepratePage ? "100%" : "250px") : "80%",
                  padding: window.innerWidth <= 768 ? (sepratePage ? "0 13px" : "0px") : "0px",
                  // margin: "0px auto",
                  maxWidth: "92%"
                }}
              />
            </div>
          )}
            
            {/* </div> */}
          </div>
          </div>
          {sepratePage ? (
              <pre
                className="text-lg font-light text-[#636363] description"
                onClick={() => pageNavigateFunction()}
                style={{
                  cursor: sepratePage || homeView ? "auto" : "pointer",
                  color: "#b2b2b2",
                  fontSize: "0.9rem",
                  fontFamily: "sans-serif",
                  fontWeight: 300,
                  whiteSpace: "pre-wrap",
                }}
                dangerouslySetInnerHTML={convertToLinks(content || "")}
              ></pre>
            ) : (
              <Read_More content={content || ""} pageNavigateFunction={pageNavigateFunction} sepratePage={sepratePage} homeView={homeView} />
            )}
          
        {/* </div> */}
        </div>
        <div className="flex items-center justify-between follow_bottom_design">
          <div className="flex items-center justify-center gap-4 sm:gap-6 relative">
            <div className="flex items-center justify-center cursor-pointer" onClick={() => (data?.readVote?.upvoteFlag ? null : UpVote(id))}>
              {/* <TopArrowIcon topArrow_color={!data?.readVote?.upvoteFlag ? "#999999" : "#ffa000"} /> */}
              <FontAwesomeIcon icon={faStar} color={!data?.readVote?.upvoteFlag ? "#999999" : "#ffa000"} />
              <p className="ml-1" style={{ marginLeft: "0.1rem" }}>
                <span
                  style={{
                    color: !data?.readVote?.upvoteFlag ? "#999999" : "#ffa000",
                  }}
                >
                  {data?.readVote?.upvoteCount ? data?.readVote?.upvoteCount : 0}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-center cursor-pointer" onClick={() => (data?.readVote?.downvoteFlag ? null : DownVote(id))}>
              {/* <DownArrow1Icon downArrow_color={!data?.readVote?.downvoteFlag ? "#999999" : "#ffa000"} /> */}
              <img src={data?.readVote?.downvoteFlag ? star2 : star} alt="Down arrow" height="35px" width="30px" />

              <p className="ml-1">
                <span
                  style={{
                    color: !data?.readVote?.downvoteFlag ? "#999999" : "#ffa000",
                  }}
                >
                  {data?.readVote?.downvoteCount ? data?.readVote?.downvoteCount : 0}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-center cursor-pointer">
              {/* <img src={ChatIcon} alt="chat icon" className="text-[#999999] sm:text-xl cursor-pointer icon3" onClick={() => showCommentHandel(id)} height={"17px"} width={"20px"} /> */}
              <FontAwesomeIcon icon={faComment} onClick={() => showCommentHandel(id)} style={{ color: "#929292", fontSize: "19px" }} />
              <p className="ml-1">
                <span
                  style={{
                    color: "#999999",
                  }}
                >
                  {commentCount || 0}
                </span>
              </p>
            </div>
            <div className={`dropdown_menu-8 ${Share ? "show" : ""}`}>
              <FacebookShareButton url={`${shareUrl}${slugUrl}`} onClick={() => handleFacebookShareClick(id)} quote="Check out this profile photo!">
                <button
                  type="button"
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                  class="mb-2 inline-block rounded bg-[#1877f2] px-5 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512" style={{ width: "15px", height: "auto" }}>
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                </button>
              </FacebookShareButton>
              <RedditShareButton url={`${shareUrl}${slugUrl}`} onClick={() => handleFacebookShareClick(id)} title="Check out this profile photo!">
                <button
                  type="button"
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                  className="mb-2 inline-block rounded bg-[#FF4500] px-4 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" style={{ width: "25px", height: "auto" }}>
                    <path d="M440.64 240.57c-5.36 0-10.39 2.01-14.32 5.32-25.09-17.53-58.67-28.81-96.32-30.8l19.41-87.16 61.33 13.6c.12 8.65 7.04 15.65 15.76 15.65 8.81 0 15.96-7.15 15.96-15.96 0-8.81-7.15-15.96-15.96-15.96-6.57 0-12.21 3.94-14.57 9.65l-67.8-15.04c-2.02-.53-4.11-.45-6.1.08-2.04.54-3.91 1.65-5.42 3.2-1.5 1.57-2.61 3.56-3.14 5.7L326.3 213.9c-39.52-1.92-75.31 6.29-101.41 21.3-5.36-5.61-12.8-9.1-21.02-9.1-17.03 0-30.88 14.58-30.88 32.57 0 8.09 2.87 15.42 7.56 20.9-2.68 1.14-5.23 2.41-7.65 3.86-25.11 14.61-41.22 39.9-41.22 67.73 0 43.92 48.73 79.54 108.81 79.54 38.09 0 72.03-12.08 92.73-31.02 6.14 2.74 13.02 4.3 20.36 4.3 7.44 0 14.44-1.57 20.66-4.39 21.29 19.18 55.71 31.1 93.71 31.1 60.08 0 108.81-35.62 108.81-79.54 0-27.83-16.11-53.12-41.22-67.73-2.48-1.45-5.04-2.72-7.71-3.86 4.61-5.41 7.47-12.74 7.47-20.73 0-17.99-13.85-32.57-30.88-32.57zM208 296.5c12.22 0 22.14 11.52 22.14 25.75s-9.92 25.75-22.14 25.75-22.14-11.52-22.14-25.75S195.78 296.5 208 296.5zm96.36 73.43c-14.51 14.14-39.53 15.48-54.33 0-1.8-1.8-1.8-4.7 0-6.5 1.8-1.8 4.7-1.8 6.5 0 10.64 10.57 29.68 10.57 40.31 0 1.8-1.8 4.7-1.8 6.5 0 1.78 1.8 1.78 4.7-.98 6.5zm54.05-22.68c-12.22 0-22.14-11.52-22.14-25.75s9.92-25.75 22.14-25.75 22.14 11.52 22.14 25.75-9.92 25.75-22.14 25.75z" />
                  </svg>
                </button>
              </RedditShareButton>
              <button
                onClick={() => copyUrlToClipboard(slugUrl)}
                type="button"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                className=" inline-block rounded bg-[#111111] px-4 py-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
              >
                <FontAwesomeIcon icon={faCopy} fontSize={"17px"} style={{ width: "25px", height: "auto" }} />
              </button>
            </div>

            <img
              src={ShareIcon}
              alt="share icon"
              className="text-[#999999] sm:text-xl cursor-pointer icon4"
              onClick={() => (!homeView ? setShare(!Share) : setMessageForLogin(!messageForLogin))}
              height={"17px"}
              width={"20px"}
            />

            {/* (homeView ? setMessageForLogin(!messageForLogin) : setMarked(!marked))} */}
            <div className="text-[#999999] sm:text-xl cursor-pointer icon5" onClick={() => addBookMark(id)}>
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m15.705 18.864-7-4.995-7 4.994V2.88a1.997 1.997 0 0 1 2-2h10a2 2 0 0 1 2 1.999v15.984z"
                  stroke={marked ? "#ffa000" : "#939393"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* hr line */}
        <hr className="text-[#707070] opacity-10" />

        {(showCommentSection || sepratePage) && (
          <React.StrictMode>
            {/* {Mochat follow reply section} */}
            <div className="MainReply">
              {/* left side */}
              <div className="MainReply_leftSide1">
                <img
                  src={!secondSrc?.includes("null") ? secondSrc : logo4}
                  alt="user icon"
                  style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    borderRadius: "100px",
                  }}
                />
              </div>

              {/* right side */}
              <div className="MainReply_rightSide">
                <textarea
                  type="text"
                  placeholder="Add a reply..."
                  className={`typingArea comment_typing_area${id}`}
                  value={inputValue}
                  onChange={(event) => {
                    setInputHeight(event, "35px");
                  }}
                  ref={inputFieldMessage}
                />

                <div className="MainReplyBottomPart">
                  {/* emoji */}
                  <div className="emojiIcon" ref={EmojiIcon}>
                    {/* emoji icon */}
                    <img src={DarkEmojiIcon} alt="Emoji Icon" onClick={() => setShowEmoji((val) => !val)} height={"auto"} width={"auto"} />

                    {/* emoji */}
                    {showEmoji && (
                      <EmojiPicker
                        onEmojiClick={(event) => {
                          setInputHeight1(event, "35px");
                        }}
                        autoFocusSearch={false}
                        searchDisabled
                        width="100%"
                        lazyLoadEmojis={true}
                      />
                    )}
                  </div>

                  {/* buttons */}
                  <div className="funtionButton">
                    <button
                      className="active"
                      onClick={() => CommentAddFunction(id)}
                      // onclick={()=>handleAddComment(id)}
                      disabled={isEmpty || !inputValue}
                      style={{
                        opacity: isEmpty || !inputValue ? 0.6 : 1,
                        cursor: isEmpty || !inputValue ? "not-allowed" : "pointer",
                      }}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="comment_section">
              {comments &&
                comments.length > 0 &&
                comments.map((value, index) => {
                  return (
                    <React.StrictMode key={index}>
                      <CommentAdd
                        content={value}
                        comments={comments}
                        limitedCommentShow={comments}
                        sepratePage={true}
                        parentId={id}
                        updateState={updateState}
                        getNewComentes={getNewComentes}
                        homeView={homeView}
                        setMessageForLogin={setMessageForLogin}
                        messageForLogin={messageForLogin}
                      />
                    </React.StrictMode>
                  );
                })}
              {showCommentSection && loading && (
                <p className="flex justify-center items-center">
                  <SyncLoader color="#fea500" loading={true} height={15} width={5} />
                </p>
              )}
              {!loading && comments.length == 0 && showCommentSection && <p className="empty flex justify-center items-center">No Comments..</p>}
            </div>
          </React.StrictMode>
        )}
      </div>
    </React.StrictMode>
  );
};

export default MochatPostSection;
